// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UsersPage from '@/components/UsersPage.vue';
import ImagesPage from '@/components/ImagesPage.vue';
import CustomerImagesPage from '@/components/CustomerImagesPage.vue';
import PrintPhoto from '@/components/PrintPhoto.vue';
const routes = [
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
  },
  {
    path: '/users/:userId/images',
    name: 'user-images',
    component: ImagesPage,
    props: true,
  },{
    path: '/images',
    name: 'all-images',
    component: ImagesPage,
    props: false, // or set props as needed
  },{
    path: '/customerImages',
    name: 'ImageDisplay',
    component: CustomerImagesPage,
  },{
    path: '/print-photo',
    component: PrintPhoto,
  },
  { path: '/', redirect: '/users' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
