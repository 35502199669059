<template>
  <v-container>
    <!-- Custom Buttons at the top -->
    <v-row>
      <v-col>
        <button class="custom-button" @click="viewAllPhotos">
          View All Photos
        </button>
      </v-col>
      <v-col>
        <button class="custom-button" @click="openFaceRecognitionDialog">
          Face Recognition
        </button>
      </v-col>
    </v-row>

    <!-- Search input for filtering users by Face ID -->
    <v-text-field
      v-model="searchQuery"
      label="Search Users by Face ID"
      @input="fetchUsers"
      clearable
    ></v-text-field>
    <v-progress-circular
  v-if="isLoading"
  indeterminate
  color="primary"
></v-progress-circular>

    <!-- Data table to show users -->
    <v-data-table :headers="headers" :items="users" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" @click="viewUserImages(item.uuid)">
          View Images
        </v-btn>
      </template>
    </v-data-table>

    <!-- Face Recognition Dialog -->
    <v-dialog v-model="isDialogVisible" max-width="600px">
      <v-card>
        <v-card-title>Face Recognition</v-card-title>
        <v-card-text>
          <div>
          <!-- <v-select :items="devices">
          <template v-slot:selection="{ item }">
            {{ item.label || 'Select Camera' }}
          </template>
          </v-select> -->
        </div>
          <div v-if="isCameraOpen">
            <video ref="video" :width="450" :height="337.5" autoplay></video>
            <canvas ref="canvas" style="display:none;" :width="450" :height="337.5"></canvas>
          </div>
          <!-- dropdown of all devices -->
 
          <div v-else>
            <p>Camera is closed. Please open it to use face recognition.</p>
          </div>
          <div v-if="error_message_face">
            <p>{{error_message_face}}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn @click="fetchToken">Token Rotate</v-btn> -->
          <v-text-field
          v-model="faceToken"
          label="token"
          v-if="tokenExpired"
          @input="fetchToken"
          clearable
        ></v-text-field>
          <v-btn @click="takePhoto" v-if="isCameraOpen">Capture</v-btn>
           <v-btn @click="toggleCamera">{{ isCameraOpen ? 'Close Camera' : 'Open Camera' }}</v-btn>
          <v-btn @click="closeFaceRecognitionDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchQuery: '',
      users: [],
      headers: [
        { title: 'UUID', key: 'uuid' },
        { title: 'Face UID', key: 'face_uid' },
        { title: 'Created Time', key: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      isDialogVisible: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      devices: [],
      selectedDeviceId: null,
      faceToken: null,
      error_face: false,
      error_message_face:"",
      tokenExpired: false,
    };
  },
  methods: {
    // Fetch users based on whether searchQuery (face_uid) is provided
    async fetchUsers() {
      try {
        let response;
        this.isLoading = true; // Add a loading state

        // If searchQuery is provided, fetch user by face_uid
        if (this.searchQuery) {
          response = await axios.get('https://api.capsuleer.me/insPhoto/user/get', {
            params: {
              'face_uid': this.searchQuery,
            },
            headers: {
              'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
            },
          });
          // Handle case where user might not exist
          this.users = response.data === 'NO_USER' ? [] : [response.data];
        } else {
          // If no searchQuery, fetch all users

          response = await axios.get('https://api.capsuleer.me/insPhoto/user/lists', {
            headers: {
              'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
            },
          });
          console.log(response.data);
          this.users = response.data || [];
          console.log(this.users);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        this.isLoading = false; // Reset loading state

      }finally {
      this.isLoading = false; // Reset loading state
    }
    },


    async fetchToken() {
          const expiry = new Date(Date.now() + 2592000 * 1000);
          localStorage.setItem('face_token', this.faceToken);
          localStorage.setItem('face_token_expiry', expiry);
          this.tokenExpired = false;

    },

    checkFaceToken() {
      const token = localStorage.getItem('face_token');
      
      const expiry = localStorage.getItem('face_token_expiry');
      
      if (token && expiry) {
        const expiryDate = new Date(expiry);
        const now = new Date();
        if (now < expiryDate) {
          this.faceToken = token; // Token is valid
          console.log('Face Token Retrieved:', this.faceToken);
        } else {
          this.tokenExpired = true;
          // Token expired, fetch a new one
        }
      } else {
        this.tokenExpired = true;
        // No token found, fetch a new one
      }
    
    },
    // Navigate to the user's images page
    viewUserImages(userId) {
      this.$router.push({ name: 'user-images', params: { userId } });
    },

    // Navigate to all images page
    viewAllPhotos() {
      this.$router.push({ name: 'all-images' });
    },

    openFaceRecognitionDialog() {
      this.isDialogVisible = true;
      this.switchCamera(true);  // Open the camera when dialog opens
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.switchCamera(false);
      } else {
        this.switchCamera(true);

      }
    },
    //explicitly start or stop the camera stream
    switchCamera(on){
      if(on){
        this.isCameraOpen = true;
        this.startCamera();
      }else{
        this.isCameraOpen = false;
        this.stopCameraStream();
      }
    },
    //use a different webcam

    

    async startCamera() {
  try {
    // Enumerate all the available devices
    const devices = await navigator.mediaDevices.enumerateDevices();
    var deviceInfoList = devices.filter(device => device.kind === 'videoinput');
  
    this.devices = deviceInfoList.map(device => ({
      label: device.label || `Camera ${this.devices.length + 1}`,
      deviceId: device.deviceId
    }));
    console.log('Available devices:', this.devices);
    
    // If no device is selected, select the first one
    if (!this.selectedDeviceId && this.devices.length > 0) {
      this.selectedDeviceId = this.devices[0].deviceId;
    }

    const constraints = {
      video: { deviceId: this.selectedDeviceId ? { exact: this.selectedDeviceId } : undefined }
    };
    
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    this.$refs.video.srcObject = stream;
  } catch (error) {
    console.error('Error accessing webcam:', error);
  }
},

    stopCameraStream() {
      const stream = this.$refs.video.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
      this.$refs.video.srcObject = null;
    },

    takePhoto() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, 450, 337.5);
      this.isPhotoTaken = true;
      // Optionally, you can process the captured image data here
      const imageData = canvas.toDataURL('image/png').split(';base64,')[1];
      console.log('Captured Image Data:', imageData);
      this.Recognize(imageData);
      this.switchCamera(false);
      //console.log('Captured Image Data:', imageData);
      // this.closeFaceRecognitionDialog();
    },

    closeFaceRecognitionDialog() {
      this.isDialogVisible = false;
      this.switchCamera(false);  // Open the camera when dialog opens
    },
    async Recognize(base64){
      const accessToken = this.faceToken; // Replace with your actual access token
      console.log('Access Token:', accessToken);
      const url = `https://aip.baidubce.com/rest/2.0/face/v3/search?access_token=${accessToken}`;
      const data = {
          image: base64,
          image_type: "BASE64",
          group_id_list: "default",
          quality_control: "LOW",
          match_threshold: 80,
      };

      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json; charset=UTF-8'
          },
          body: JSON.stringify(data),
          // The following line is optional if you want to ignore SSL errors (not recommended for production)
          // mode: 'no-cors' 
      })
      .then(async response => {
          if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
          }
          {
            var responseJson = await response.json();
            if(responseJson.error_code == 0){
              this.error_message_face = "";
              this.error_face = false;
              console.log('Face Recognition Result:', responseJson.result.user_list[0].user_id);
              this.searchQuery = responseJson.result.user_list[0].user_id;
              this.fetchUsers();
              this.closeFaceRecognitionDialog();


            }else{
              this.error_message_face = responseJson.error_msg;
              this.error_face = true;
              this.switchCamera(true);
            }
            
          }
      })
      // .then(data => {
      //     console.log(data); // Handle the response data here
      // })
      .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
      });

    },clearCustomerImages() {
      const channel = new BroadcastChannel('customer-images-channel');
      channel.postMessage({ images: [] }); // Send an empty array
    },
  },


  // Fetch all users on page load
  mounted() {
    this.fetchUsers();
    this.checkFaceToken();
    // setTimeout(() => {
    //   this.fetchUsers();
    //   this.clearCustomerImages();
    // }, 10000);
  },
};
</script>


<style scoped>
.custom-button {
  background-color: #6200ea; /* Primary color */
  color: white; /* Text color */
  padding: 16px 32px; /* Padding for large size */
  font-size: 1.25rem; /* Font size */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition */
  width: 100%; /* Full width */
}

.custom-button:hover {
  background-color: #3700b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.custom-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(98, 0, 234, 0.5); /* Custom focus outline */
}
</style>