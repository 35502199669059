<template>
  <v-container>
    <v-card class="mx-auto" max-width="800">
      <v-card-title>Print Photo</v-card-title>
      <v-card-text>
        <div v-if="photoUrl">
          <img :src="photoUrl" alt="Photo to Print" class="print-photo" />
        </div>
        <div v-else>
          <p>No photo URL provided.</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      photoUrl: null,
    };
  },
  created() {
    // Get the photo URL from query parameters
    this.photoUrl = this.$route.query.photoUrl || null;

    // Automatically trigger print if URL is provided
    if (this.photoUrl) {
      this.triggerPrint();
    }
  },
  methods: {
    triggerPrint() {
      // Wait for the image to load, then trigger the print dialog
      const image = new Image();
      image.src = this.photoUrl;
      image.onload = () => {
        window.print();
      };
      image.onerror = () => {
        console.error('Error loading image for printing.');
      };
    },
  },
};
</script>

<style scoped>
.print-photo {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
</style>
