<template>
  <v-container>
    <v-card>
      <v-card-title>查看照片{{ this.$route.params.userId }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="filters.sessionId" label="Session ID" clearable></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="filters.photoType" label="Photo Type" clearable></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="filters.photoName" label="Photo Name" clearable></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="filters.isPaid"
              :items="['true', 'false']"
              label="Is Paid"
              clearable
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="actions">
        <v-btn @click="goBack" color="secondary" class="button-modern">
          <v-icon left>mdi-arrow-left</v-icon>
          返回并清除购物车
        </v-btn>
        <v-btn @click="fetchImages" color="primary" class="button-modern">
          施加过滤器
        </v-btn>
        <v-btn @click="clearFilters" color="grey darken-1" class="button-modern">
          清除过滤器
        </v-btn>
        <v-spacer></v-spacer>
       
        <!-- //a toggle -->
        <v-btn @click="openCart" color="green">查看购物车</v-btn>
        <!-- <v-btn @click="pushCustomerImagesPage" color="green">Push Customer Images</v-btn> -->
        <v-btn @click="checkout" color="red">结账</v-btn>

        <!-- <v-btn @click="clearCustomerImages" color="red">Reset Customer View</v-btn> -->
        
      </v-card-actions>
    </v-card>
    <br>

    <!-- Image Gallery -->
    <div class="gallery">
      <div
        v-for="image in images"
        :key="image.uuid"
        class="image-card"
        @click="openImageDrawer(image)"
        @contextmenu.prevent="openDrawer(image)"
      >
        <img
          :src="`https://inspiration-photo-oss-internal.jcstudio.site/${image.oss_filename}`"
          alt="Image Preview"
        />
        <div class="image-info">
          <h4>{{ image.photo_name }}</h4>
          <p>{{ image.photo_type }}</p>
          <p>{{ image.price }}元</p>
        </div>
      </div>
    </div>

    <v-pagination v-model="page" :length="totalPages" @update:model-value="onChangePagnition"></v-pagination>

    <!-- Drawer for Image Selection -->
    <v-navigation-drawer v-model="imageDrawer" right sticky>
      <v-card>
        <v-card-title>Select Version for {{ selectedImage.photo_name }}</v-card-title>
        <v-card-text>
  <v-radio-group v-model="selectedVersion">
    <v-radio label="电子版" value="digital"></v-radio>
    <v-radio label="印刷版" value="physical"></v-radio>
    <v-radio label="免费电子版(PROMO)" value="digitalfree"></v-radio>

  </v-radio-group>
  <v-text-field
    v-model="selectedImage.price"
    label="Price"
  ></v-text-field>
</v-card-text>
        <v-card-actions>
          <v-btn @click="addToCart" color="primary">Add to Cart</v-btn>
          <v-btn @click="imageDrawer = false" color="grey">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <!-- Drawer for Cart -->
    <v-navigation-drawer v-model="cartDrawer" left>
      <v-card>
        <v-card-title>Your Shopping Cart</v-card-title>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(item, index) in cart"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.photo_name }} - {{ item.version }}</v-list-item-title>
                <!-- //photo preview -->
                <v-img
                  :src="`https://inspiration-photo-oss-internal.jcstudio.site/${item.oss_filename}`"
                  class="v-img-fixed"
                ></v-img>
                <v-list-item-subtitle>Price: {{ item.price }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click="removeFromCart(index)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-btn @click="cartDrawer = false" color="grey">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <!-- Drawer for Modifying Image -->
    <v-navigation-drawer v-model="drawer" right sticky>
      <v-card>
        <v-card-title>Edit Image {{selectedImage.uuid}}</v-card-title>
        <v-card-text>
          <v-text-field v-model="selectedImage.photo_name" label="Photo Name"></v-text-field>
          <v-text-field v-model="selectedImage.photo_type" label="Photo Type"></v-text-field>
          <v-select
            v-model="selectedImage.is_paid"
            :items="['true', 'false']"
            label="Is Paid"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updateImage" color="primary">Update</v-btn>
          <v-btn @click="deleteImage" color="red">Delete</v-btn>
          <v-btn @click="drawer = false" color="grey">Cancel</v-btn>
        </v-card-actions>
        <v-card-actions>
          <a
            :href="downloadImageUrl"
            download
            target="_blank"
            class="download-link"
          >
            Download Image
          </a>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </v-container>
  <v-dialog v-model="checkoutDrawer" fullscreen hide-overlay>
  <v-card>
    <v-card-title>结账信息</v-card-title>
    <v-card-text>
  <v-list style="display: flex; flex-wrap: wrap;">
    <v-list-item v-for="(item, index) in cart" :key="index">
      <v-list-item-content>
        <v-img :src="`https://inspiration-photo-oss-internal.jcstudio.site/${item.oss_filename}`" style="width: 200px; object-fit: cover;"></v-img>
        <v-list-item-title>{{ item.photo_name }} - {{ item.version }}</v-list-item-title>
        <v-list-item-subtitle>Price: {{ item.price }}元</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>总计价格: {{ totalAmount }}元</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</v-card-text>
    <v-card-actions>
      <v-btn @click="cancelCheckout">取消</v-btn>
      <v-btn @click="confirmCheckout" color="primary">我确定它已付款</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      images: [],
      page: 1,
      limit: 6,
      totalPages: 1,
      filters: {
        sessionId: '',
        photoType: '',
        photoName: '',
        isPaid: null,
      },
      drawer: false,
      imageDrawer: false,
      cartDrawer: false,
      selectedImage: {},
      selectedVersion: 'digital',
      pushShoppingcart: false,
      checkoutDrawer:false,
      cart: [],
      
    };
  },
  computed: {
    downloadImageUrl() {
      return `https://inspiration-photo-oss-internal.jcstudio.site/${this.selectedImage.oss_filename}`;
    },
    downloadFileName() {
      return this.selectedImage.photo_name || 'downloaded-image.jpg';
    },
    totalAmount() {
      return this.cart.reduce((total, item) => total + parseFloat(item.price), 0);
    },
    userImagePage() {
      return `https://insphoto.jcstudio.site/?owner_uuid=${this.$route.params.userId}`;
    },
  },
  methods: {
    goBack() {
      this.clearCustomerImages();
      this.$router.go(-1);
    },
    confirmCheckout(){
      const channel = new BroadcastChannel('customer-images-channel');
      // var sentImages = [];
      channel.postMessage({ downloadableUrl:this.userImagePage }); // Send images array

      // sentImages. = ;

      //set all of these images to paid
      this.cart.forEach(async (item) => {
        try {
          await axios.patch(`https://api.capsuleer.me/insPhoto/photo/update?uuid=${item.uuid}`, {
            is_paid: true,
          }, {
            headers: {
              'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
            },
          });
        } catch (error) {
          console.error('Error updating image:', error);
        }
      });
      this.cart = [];
      this.checkoutDrawer = false;
      

    },
    cancelCheckout() {
      this.checkoutDrawer = false;
      this.pushCustomerImagesPage();
    },
    checkout() {
  this.checkoutDrawer = true; // Open the cart drawer
  this.pushShoppingcartPage();
  // const channel = new BroadcastChannel('customer-images-channel');
  // channel.postMessage({ carts: this.cart, useShoppingcart: true }); // Push the cart
},
    pushCustomerImagesPage() {
      const channel = new BroadcastChannel('customer-images-channel');
      var sentImages = [];
      this.images.forEach(image => {
        sentImages.push({
          uuid: image.uuid,
          photo_name: image.photo_name,
          photo_type: image.photo_type,
          oss_filename: image.oss_filename,
        });
      });
      channel.postMessage({ images: sentImages, page: this.page,totalPage: this.totalPages,useShoppingcart: false
       }); // Send images array

      console.log('Sent images:', this.images);
    },
    pushShoppingcartPage() {
      const channel = new BroadcastChannel('customer-images-channel');
      var carts = [];
      this.cart.forEach(element => {
        carts.push({...element})
      });
      channel.postMessage({ carts: carts, page: this.page,totalPage: this.totalPages,useShoppingcart: true,totalPrice:
        this.totalAmount }); // Send images array

      console.log('Sent images:', this.cart);
    },
    async fetchImages() {
      try {
        const params = {
          page: this.page,
          limit: this.limit,
          owner_uuid: this.$route.params.userId,
        };

        if (this.filters.sessionId) {
          params['session-id'] = this.filters.sessionId;
        }
        params['photo-type'] = this.filters.photoType || '!=userLogin,generated_discarded';

        if (this.filters.photoName) {
          params['photo-name'] = this.filters.photoName;
        }
        if (this.filters.isPaid !== null) {
          params.is_paid = this.filters.isPaid;
        }

        const response = await axios.get('https://api.capsuleer.me/insPhoto/photo/all', {
          params,
          headers: {
            'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
          },
        });

        console.log('Fetched images:', response.data);
        this.images = response.data.photos;
        this.totalPages = response.data.totalPages;
        this.pushCustomerImagesPage();
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },
    onChangePagnition(page) {
      this.page = page;
      this.fetchImages();
    },
    clearFilters() {
      this.filters = {
        sessionId: '',
        photoType: '',
        photoName: '',
        isPaid: null,
      };
      this.fetchImages();
    },
    openImageDrawer(image) {
      this.selectedImage = { ...image };
      this.imageDrawer = true;
    },
    openDrawer(image) {
      this.selectedImage = { ...image }; // Clone the image object
      this.drawer = true; // Open the drawer for editing
    },
    addToCart() {
      const existingIndex = this.cart.findIndex(item => item.uuid === this.selectedImage.uuid);
  
  if (existingIndex !== -1) {
    // const existingItem = this.cart[existingIndex];
    
    // Check if there's already a digital version in the cart
    const hasDigitalVersion = this.cart.some(item => item.uuid === this.selectedImage.uuid && item.version === 'digital');

    if (this.selectedVersion === 'digital' && hasDigitalVersion) {
      //overwrite it
      this.cart[existingIndex] = {
        ...this.selectedImage,
        version: this.selectedVersion,
      };

      this.imageDrawer = false;

      return;
    }
    if(this.selectedVersion === 'physical' && hasDigitalVersion){
      //overwrite it
      this.cart[existingIndex] = {
        ...this.selectedImage,
        version: this.selectedVersion,
      };

      this.imageDrawer = false;

      return;
    }
  }
  if(this.selectedVersion === 'digitalfree'){
    this.selectedImage.price = 0;
  }

  // Add the new item to the cart
  this.cart.push({
    ...this.selectedImage,
    version: this.selectedVersion,
  });

      this.imageDrawer = false;
    },
    openCart() {
      this.cartDrawer = true;
    },
    removeFromCart(index) {
      this.cart.splice(index, 1);
    },
    async updateImage() {
      try {
        await axios.patch(`https://api.capsuleer.me/insPhoto/photo/update?uuid=${this.selectedImage.uuid}`, {
          photo_name: this.selectedImage.photo_name,
          photo_type: this.selectedImage.photo_type,
          is_paid: this.selectedImage.is_paid,
        }, {
          headers: {
            'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
          },
        });
        this.drawer = false;
        this.fetchImages();
      } catch (error) {
        console.error('Error updating image:', error);
      }
    },clearCustomerImages() {
      const channel = new BroadcastChannel('customer-images-channel');
      channel.postMessage({ images: [] }); // Send an empty array
    },
    async deleteImage() {
      try {
        await axios.patch(`https://api.capsuleer.me/insPhoto/photo/update?uuid=${this.selectedImage.uuid}`, {
          is_deleted: true,
        }, {
          headers: {
            'X-Inspiration-Auth': '80544f34795f28e520c0af7de9e30b03f027a466c7a33ce224671bebbc1577a7',
          },
        });

        this.images = this.images.filter(image => image.uuid !== this.selectedImage.uuid);
        this.drawer = false;
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    },
  },
  mounted() {
    this.fetchImages();
  },
};
</script>

<style scoped>
.v-img-fixed {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-card {
  width: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-img {
  height: 100%;
  width: 100%;
}

.filters-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.image-card {
  width: 400px;
  height: 600px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-info {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.image-info h4 {
  margin: 0;
  font-size: 1.2rem;
}

.image-info p {
  margin: 0;
  font-size: 1rem;
  opacity: 0.8;
}

.v-pagination {
  margin-top: 30px;
  justify-content: center;
}
.v-navigation-drawer{
  height: 100vh;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
}

.button-modern {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 500;
}

.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1976d2;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.download-link:hover {
  background-color: #1565c0;
}
</style>
