<template>
    <v-container fluid class="exhibition-container">
      <v-card v-if="showQrCode" flat class="transparent-card">
        <div  class="qr-code">
  <img :src="qrCodeUrl" alt="QR Code" />
</div>
        </v-card>
      <v-card v-else flat class="transparent-card">
        <v-card-title v-if="loaded" class="page-title">您的照片</v-card-title>
        <v-card-title v-else class="page-title">在此处结账、打印或获取你的照片</v-card-title>

        <!-- //showing a placseholder image -->
        <!-- <img 
  v-if="!loaded" 
  src="/price.png" 
  alt="Placeholder Image" 
  style="width: 600px;  margin: 0 auto; display: block;"
/> -->
        <!-- Watermark -->
        <div v-if="loaded" class="watermark">SAMPLE</div>
  
        <!-- Image Gallery -->
        <div v-if="!useShoppingcart" class="gallery">
          <div
            v-for="image in images"
            :key="image.uuid"
            class="image-card"
          >
            <img
              :src="`https://inspiration-photo-oss-internal.jcstudio.site/${image.oss_filename}`"
              alt="Image Preview"
            />
            <div class="image-info">
              <h5>{{ image.photo_name }}</h5>
              <!-- <p>{{ image.photo_type }}</p> -->
              <!-- the index of this image on this page -->
              <p>{{ images.indexOf(image) + 1 }}</p>
            </div>
          </div>
         <!-- Pagination Display -->
         <!-- <div v-if="totalPages > 0 && loaded" class="pagination-info">
          <p>当前第{{ currentPage }}页，共计{{ totalPages }}页</p>
        </div> -->
      </div>
      <div v-if="useShoppingcart">
        <!-- <v-card-title class="page-title">购物车</v-card-title> -->
        <div class="gallery">
          <div
            v-for="cart in carts"
            :key="cart.uuid"
            class="image-card"
          >
            <img
              :src="`https://inspiration-photo-oss-internal.jcstudio.site/${cart.oss_filename}`"
              alt="Image Preview"
            />
            <div class="image-info">
              <h4>{{ cart.photo_name }}</h4>
              <p>{{ cart.price }}元</p>
              <p>{{ cart.version == "digital"?"电子版":"打印版" }} 1 张</p>
            </div>
          </div>
          
        </div>
          
        
      </div>
      <div v-if="useShoppingcart" class="page-title" >
            <h4>总价</h4>
            <p>{{ totalPrice }}元</p>
          </div>
      </v-card>
    </v-container>
  </template>
  
  <script>

  export default {
    data() {
      return {
        images: [], // Initially empty, will be populated by BroadcastChannel
        carts:[],
        loaded: false, // Flag to indicate if images have been loaded
        currentPage: 1, // Current page number
        totalPages: 0, // Total number of pages
        useShoppingcart: false,
        downloadableUrl: null, // New property for the downloadable URL
        showQrCode: false,
      };
    },
  computed: {
    qrCodeUrl() {
      return this.downloadableUrl
        ? `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.downloadableUrl)}&size=200x200`
        : null;
    },
  },

    methods: {
      // Set up BroadcastChannel listener for receiving images and pagination data
      setupBroadcastChannel() {
        const channel = new BroadcastChannel('customer-images-channel');
        channel.onmessage = (event) => {
          if (event.data) {
            if (event.data.downloadableUrl) {
              console.log('showQrCode:', this.showQrCode)
              this.showQrCode = true;
            // this.downloadableUrl = event.data.downloadable_url;
            this.onDataUrlChange(event.data.downloadableUrl);
          } else {
            this.showQrCode = false;
          }
            if((event.data.images && event.data.images.length === 0) && event.data.carts && event.data.carts.length === 0){
              this.loaded = false;
              this.images = [];
              this.carts = [];
            }
            if(!event.data.useShoppingcart){
                // Update images array if available
                this.useShoppingcart = false;
              if (event.data.images) {
                this.images = event.data.images;
                this.loaded = this.images.length > 0;
              }
              // Update current page and total pages if provided
              if (event.data.page && event.data.totalPage) {
                this.currentPage = event.data.page;
                this.totalPages = event.data.totalPage;
                console.log('totalPages:', this.totalPages)
              }  
            }else{
                this.carts = event.data.carts;
                this.useShoppingcart = true;
                this.totalPrice = event.data.totalPrice;
            }
            
            console.log('Received data:', event.data);
          }
        };
      },
      onDataUrlChange(url) {
        this.downloadableUrl = url;
    },
    },
    mounted() {
      this.setupBroadcastChannel(); // Initialize BroadcastChannel listener
    },
  };
  </script>
  
  <style scoped>
  /* Exhibition-like styling */
  .exhibition-container {
    background-color: #000; /* Dark background to simulate a digital art gallery */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  /* Transparent card for media display */
  .transparent-card {
    background-color: transparent;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page-title {
    color: #fff;
    text-align: center;
    font-size: 2.5rem; /* Increased font size for impact */
    letter-spacing: 3px; /* More space for elegance */
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  
  /* Glowing watermark */
  .watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.05); /* Even more subtle */
    font-size: 16rem; /* Slightly larger for a stronger visual presence */
    font-weight: bold;
    letter-spacing: 25px;
    text-transform: uppercase;
    pointer-events: none; /* Don't interfere with other interactions */
    user-select: none;
    z-index: 999;
  }
  
  /* Gallery layout similar to art exhibitions */
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding: 20px;
  }
  
  /* Image card with hover effect */
  .image-card {
    width: 300px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1); /* Slight transparency to blend with dark background */
  }
  
  .image-card img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    border-radius: 12px;
  }

  /* Image info */
  .image-info {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Black translucent background */
    color: white;
    padding: 10px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }
  
  .image-info h4 {
    margin: 0;
    font-size: 1.4rem; /* Slightly larger for readability */
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .image-info p {
    margin: 0;
    font-size: 1.1rem; /* Increased for better legibility */
    opacity: 0.9; /* More prominent text */
  }
  
  /* Pagination info styling */
  .pagination-info {
    margin-top: 30px;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }

  .qr-code {
  text-align: center;
  margin-top: 20px;
}

.qr-code img {
  width: 200px; /* Adjust size as needed */
  height: 200px;
}
  </style>
  